import React, { useState, useEffect, useRef } from 'react';

type Options = {
  threshold?: number;
  reappear?: boolean;
  rootMargin: string;
  initiallyVisible?: boolean;
};

/**
 * This hook is used to detect when an element is visible on the screen. If containerRef is visible, it sets isVisible to true.
 * @param options - Options for configuring the IntersectionObserver.
 * @returns A tuple containing a ref to the container element and a boolean indicating its visibility.
 */
const useScreenElements = ({
  initiallyVisible = false,
  ...options
}: Options): [React.RefObject<HTMLDivElement>, boolean] => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(initiallyVisible);

  const makeAppear = (entries: any) => {
    const [entry] = entries;

    if (entry.isIntersecting) setIsVisible(true);
  };

  const makeAppearRepeating = (entries: any) => {
    const [entry] = entries;

    setIsVisible(entry.isIntersecting);
  };

  const callBack = options.reappear ? makeAppearRepeating : makeAppear;

  useEffect(() => {
    const containerRefCurrent = containerRef.current;
    const observer = new IntersectionObserver(callBack, {
      threshold: options.threshold,
      rootMargin: options.rootMargin
    });

    if (containerRefCurrent) observer.observe(containerRefCurrent);

    return () => {
      if (containerRefCurrent) {
        observer.unobserve(containerRefCurrent);
      }
    };
  }, [containerRef, options, callBack]);

  return [containerRef, isVisible];
};

export default useScreenElements;
