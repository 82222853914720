const propertyActionConstant = {
  addNotes: 'add-notes',
  saveProperty: 'save-property',
  saveSchool: 'save-school',
  makeOffer: 'make-offer',
  arrangeViewing: 'arrange-viewing',
  mortgageAdvice: 'mortgage-advice',
  registerInterest: 'register-interest',
  deleteAfterLogin: 'delete-after-login',
  viewVideo: 'view-video'
};

export interface PropertyActionConstantType {
  addNotes: 'add-notes';
  saveProperty: 'save-property';
  saveSchool: 'save-school';
  makeOffer: 'make-offer';
  arrangeViewing: 'arrange-viewing';
  mortgageAdvice: 'mortgage-advice';
  registerInterest: 'register-interest';
  deleteAfterLogin: 'delete-after-login';
  viewVideo: 'view-video';
}

export default propertyActionConstant;
