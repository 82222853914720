import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { RecommendedPropertiesSection } from '@components/RecommendedProperties';
import { useUserFromStorage } from '@foxtons/fdl/utils/customHooks/useUserFromStorage';
import { getSearchHistory } from '@utils/helperFunctions';
import {
  getMarketMeasuresProperties,
  HttpResponseDataType,
  fetchRecommendedProperties
} from '@utils/httpRequestConfigs/httpRequests';

type FilterTypes = 'All' | 'Sales' | 'Lettings';

interface SearchPropertiesBoxProps {
  richText: string;
  isAnimated?: boolean;
  marketPageView?: boolean;
  marketPageData?: any;
  isLivingIn?: boolean;
}

interface SearchHistoryObject {
  properties: string[];
}

// Transform raw API data into the required format
const transformMarketProperties = (properties: any) => {
  return properties?.map((property) => {
    const isTopTenancy = property.instructionType === 'letting';
    const isQuickSale = property.instructionType === 'sale';

    const photos = property.photos.map((p: string, index: number) => ({
      index,
      propertyReference: property.propertyReference,
      filename: p,
      timestamp: ''
    }));

    return {
      ...property,
      postcodeShort: property.postCodeShort,
      propertyBlob: {
        assetInfo: {
          assets: { photos }
        }
      },
      isTopTenancy,
      isQuickSale
    };
  });
};

const SearchPropertiesBox = ({
  richText,
  isAnimated,
  marketPageView = false,
  marketPageData
}: SearchPropertiesBoxProps) => {
  const [user] = useUserFromStorage();

  // State management
  const [allProperties, setAllProperties] = useState<HttpResponseDataType[]>(
    []
  );
  const [tabs, setTabs] = useState(['All']);
  const [isLoading, setIsLoading] = useState(false);
  const [marketFilterType, setMarketFilterType] = useState<FilterTypes>('All');
  const [savedProperties, setSavedProperties] = useState<
    HttpResponseDataType[]
  >([]);

  // Fetch market properties only once
  const fetchMarketProperties = useCallback(async () => {
    if (!marketPageData) return null;

    const { encodedPolygon, location } = marketPageData;

    setIsLoading(true);

    try {
      const response = await getMarketMeasuresProperties(
        encodedPolygon,
        location?.lat,
        location?.lon,
        20
      );

      if (response.success) {
        const transformedProperties = transformMarketProperties(
          response?.response?.data
        );

        setAllProperties(transformedProperties);

        // Set available tabs based on property types
        const newTabs = ['All'];

        if (transformedProperties.some((x) => x.isQuickSale)) {
          newTabs.push('Sales');
        }

        if (transformedProperties.some((x) => x.isTopTenancy)) {
          newTabs.push('Lettings');
        }

        setTabs(newTabs);
      }
    } catch (error) {
      console.error('Error fetching market properties:', error);
    } finally {
      setIsLoading(false);
    }
  }, [marketPageData]);

  // Fetch recommended properties
  const fetchRecommendedPropertyData = useCallback(async () => {
    setIsLoading(true);

    try {
      const [salePropertiesList, lettingPropertiesList] = await Promise.all([
        fetchRecommendedProperties('sale'),
        fetchRecommendedProperties('letting')
      ]);

      const saleProperties = salePropertiesList?.length
        ? salePropertiesList
        : [];
      const lettingProperties = lettingPropertiesList?.length
        ? lettingPropertiesList
        : [];

      const maxLen = Math.max(saleProperties.length, lettingProperties.length);
      const recommendedProperties = Array.from({ length: maxLen }).reduce(
        (acc, _, i) => {
          if (saleProperties[i] !== undefined) acc.push(saleProperties[i]);
          if (lettingProperties[i] !== undefined)
            acc.push(lettingProperties[i]);

          return acc;
        },
        []
      );

      if (recommendedProperties) {
        const searchHistory: SearchHistoryObject = getSearchHistory();
        const processedProperties = recommendedProperties.map((d) => {
          if (searchHistory?.properties?.includes(d?.propertyId)) {
            return { ...d, isLastViewed: true };
          }

          if (!searchHistory?.properties?.length) {
            return { ...d, isNew: true };
          }

          return { ...d, isRecommendedProperty: true };
        });

        const sortedProperties = processedProperties.sort((a, b) => {
          if (a.isLastViewed && !b.isLastViewed) return -1;
          if (!a.isLastViewed && b.isLastViewed) return 1;

          return 0;
        });

        setAllProperties(sortedProperties);
      }
    } catch (error) {
      console.error('Error fetching recommended properties:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Filter properties based on selected tab
  const filteredProperties = useMemo(() => {
    if (marketFilterType === 'All') return allProperties;

    const types = {
      Sales: 'sale',
      Lettings: 'letting'
    };

    return allProperties.filter(
      (p) => p.instructionType === types[marketFilterType]
    );
  }, [allProperties, marketFilterType]);

  // Initial data fetch
  useEffect(() => {
    if (marketPageView) {
      fetchMarketProperties();
    } else {
      fetchRecommendedPropertyData();
    }
  }, [marketPageView, fetchMarketProperties, fetchRecommendedPropertyData]);

  return (
    <RecommendedPropertiesSection
      title={richText}
      recommendedPropertiesData={filteredProperties}
      baseUrlPropertyType={'properties-for-sale'}
      userToken={user?.isLoggedIn}
      savedProperties={savedProperties}
      settingSavedProp={setSavedProperties}
      isRecommendedProperty={false}
      isAnimated={isAnimated}
      isSearch={!marketPageView}
      isLoading={isLoading}
      handleSearchNow={() => {
        sessionStorage.setItem('showSearchWidget', 'true');
        window.dispatchEvent(new Event('storage'));
      }}
      tabs={marketPageView ? tabs : []}
      activeTab={marketFilterType}
      handleTabChange={(val: string) => setMarketFilterType(val as FilterTypes)}
      marketPageView={marketPageView}
      isLivingIn
    />
  );
};

export default memo(SearchPropertiesBox);
